import {Controller} from '@hotwired/stimulus';
import {getComponent} from '@symfony/ux-live-component';
import {now} from "moment";
//import {v4 as uuidv4} from 'uuid';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    _files = []

    async initialize() {
        let that = this;
        this.component = await getComponent(this.element);
        //that.upload_error = $(".message-upload-error");

        /*let scrollToId = new URLSearchParams(window.location.search).get("scrollTo");
        if (scrollToId) {
            let targetElement = document.getElementById(scrollToId);
            if (targetElement) {
                targetElement.style.transition = "box-shadow 1s ease-out";
                targetElement.style.boxShadow = "0px 0px 20px 7px #0000008f";
                targetElement.scrollIntoView({behavior: "smooth", block: "center"});
                setTimeout(() => {
                    targetElement.style.boxShadow = "none";
                }, 1000);
            }
        }*/

        document.addEventListener("click", function (event) {
            let $target = event.target;

            // Datei löschen
            if ($target.closest(".fileupload-remove-item")) {
                let $element = $target.closest(".fileupload-remove-item");

                let id = $element.closest(".file-wrapper")?.id;
                //$element.closest(".file-wrapper")?.remove();

                if (id) {
                    that._files = that._files.filter(file => file.id !== id)
                    that.component.set('files', that._files);
                    that.component.render();
                }
                // Nachricht löschen
            } else if ($target.closest(".remove-message")) {
                if (confirm("Soll diese Nachricht wirklich gelöscht werden?")) {
                    let $element = $target.closest(".remove-message");
                    that.component.action("removeMessage", {"id": $element.closest(".message-col")?.id});
                }
            }
        });

        /*document.querySelectorAll(".remove-message").forEach(function ($btn) {
            $btn.addEventListener("click", function () {

            });
        });*/

        this.component.on('render:finished', (component) => {
            // do something after the component re-renders
        });

        if (document.getElementById('foerdernummerMessageForm')) {
            document.getElementById('foerdernummerMessageForm').addEventListener('submit', (e) => {
                e.preventDefault();

                // Value des Textareas in Komponente speichern
                that.component.set("message", document.getElementById("foerdernummer_message_message").value);

                // Wert übergeben, wenn die Nachricht vom Admin als AS verschickt wird
                if (e.submitter?.classList?.contains("send-as")) {
                    that.component.set("sendAS", true);
                } else {
                    that.component.set("sendAS", false);
                }

                // Editor Inhalt leeren
                tinymce?.get("foerdernummer_message_message")?.setContent("");
                that._files = [];
            });

            let messages_file_add = document.getElementById('messages_file_add');
            messages_file_add.addEventListener('click', (e) => {
                document.getElementById('messages_files_input').click();
            });

            let messages_files_input = document.getElementById('messages_files_input');
            messages_files_input.addEventListener('change', (e) => {
                let input = messages_files_input;
                if (!input.files) {
                    alert("Ihr Browser unterstützt das Hochladen von Dateien nicht. Bitte installieren Sie die neuste Version oder versuchen Sie einen anderen Browser.");
                } else {
                    for (let i = 0; i < input.files.length; i++) {
                        let id = now() + (Math.random() * 100);
                        let preview = "";
                        let mimeType = input.files[i].type;

                        if (mimeType.match(/^image\//)) {
                            preview = URL.createObjectURL(input.files[i]);
                        } else if (mimeType.match(/^application\/.*(excel|sheet).*/) || mimeType === "text/csv") {
                            preview = "/img/icons/icon-excel.svg";
                        } else if (mimeType.match(/^application\/.*(msword|\.document|\.text)$/)) {
                            preview = "/img/icons/icon-word.svg";
                        } else if (mimeType === "application/pdf") {
                            preview = "/img/icons/icon-pdf.svg";
                        } else {
                            preview = "/img/icons/icon-file.svg";
                        }

                        let file_obj = {"id": "_" + id, file: input.files[i], "preview": preview, "filename": input.files[i].name, "state": "waiting"};
                        that._files.push(file_obj);
                        that._checkAllFileUploaded();

                        that.component.set('files', that._files);
                        that.component.set('allFilesUploaded', that.allFilesUploaded);
                        document.getElementById('foerdernummerMessageForm').querySelector(':scope [type="submit"]').removeAttribute("disabled");
                    }

                    // Component rendern und upload ausführen, wenn rendering fertig ist
                    that.component.render().then(function (response) {
                        return response;
                    }).then(function () {
                        messages_files_input.value = "";
                        that.uploadFiles();
                    });


                }
            });
        }

    }

    _uploading = false;

    uploadFiles() {
        let that = this;
        if (this._uploading) {
            return;
        }

        if (this._files.length === 0) {
            return;
        }

        //that.upload_error.addClass("d-none");

        for (let i = 0; i < this._files.length; i++) {
            let file_obj = this._files[i];

            if (file_obj.state === 'waiting') {
                let xhr = new XMLHttpRequest();    // den AJAX Request anlegen

                xhr.upload.addEventListener("progress", function (event) {
                    that._handleProgress(event, file_obj);
                });
                xhr.addEventListener("load", function (event) {
                    that._handleComplete(event, file_obj).then(function (response) {
                        return response;
                    }).then(function () {
                        that.uploadFiles();
                    });
                });
                xhr.addEventListener("error", function (event) {
                    that._handleError(event, file_obj)
                });

                xhr.open('POST', '/ajax/file/upload');    // Angeben der URL und des Requesttyps

                let formdata = new FormData();    // Anlegen eines FormData Objekts zum Versenden unserer Datei
                formdata.append('file', file_obj.file);  // Anhängen der Datei an das Objekt
                xhr.send(formdata);    // Absenden des Requestsx
                break;
            }
        }

    }

    _handleProgress(event, file_obj) {
        //var value = Math.floor(event.loaded / event.total * 100);

        //let progressbar = document.querySelector('#' + file_obj.id + ' .progressbar')
        //progressbar.style.width = value + '%';
    }

    _handleError(event, file_obj) {
        file_obj.state = "error";
        this._checkAllFileUploaded()
    }

    _checkAllFileUploaded() {
        let that = this;

        let allFilesUploaded = true;
        that._files.forEach(function (file) {
            allFilesUploaded = allFilesUploaded && (file.state === "complete" || file.state === "error");
        });
        that.allFilesUploaded = allFilesUploaded;
    }

    _handleComplete(event, file_obj) {
        let that = this;

        that._clearProgressbar(file_obj);

        if (event.target.status === 400) {
            file_obj.state = 'error';
            //that.upload_error.removeClass('d-none');

            if (event.target.response === "ERROR File To Large") {
                //that.upload_error.text("Die hochgeladene Datei ist zu gross! Verkleinern Sie die Datei und versuchen Sie es erneut.");
                alert(file_obj.filename + "\nDie hochgeladene Datei ist zu gross! Verkleinern Sie die Datei und versuchen Sie es erneut.");
            } else if (event.target.response === "ERROR Mimetype Not Allowed") {
                //that.upload_error.text("Der Dateityp der hochgeladenen Datei wird nicht unterstützt. Bitte laden Sie eine andere Datei hoch.");
                alert(file_obj.filename + "\nDer Dateityp der hochgeladenen Datei wird nicht unterstützt. Bitte laden Sie eine andere Datei hoch.");
            } else {
                //that.upload_error.text("Es ist ein Fehler beim Upload aufgetreten, bitte wenden Sie sich an den Support.");
                alert(file_obj.filename + "\nEs ist ein Fehler beim Upload aufgetreten, bitte wenden Sie sich an den Support.");
            }

            that._checkAllFileUploaded();
        } else {
            file_obj.state = 'complete';
            file_obj.filepath = event.target.responseText;
            file_obj.filename = file_obj.file.name;

            that._checkAllFileUploaded();
        }

        this.component.set('files', this._files);
        this.component.set('allFilesUploaded', this.allFilesUploaded);
        return this.component.render();
    }

    _clearProgressbar(file_obj) {

        /*let progressbar = document.querySelector('#' + file_obj.id + ' .progressbar')
        progressbar.visible = false;
        progressbar.style.width = 0;
        setTimeout(
            function () {
                progressbar.visible = true;
            }, 300);*/
    }

}
